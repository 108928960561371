<template>
  <div>
    <div>
      <div>
        <breadcrumb name="Cars" title="Categories" subtitle="Add New" @onTitleClicked="viewList"/>
      </div>
      <div class="body">
        <vs-row>
          <vs-col vs-lg="6" vs-sm="12">
            <div class="drop-down">
              <label for="brands">Choose Brand</label>
              <select id="brands" v-model="brand_id">
                <option value="0" selected disabled>Choose Brand</option>
                <option v-for="(brand, index) in brands" :value="brand.id" :key="index">{{brand.name_en}}</option>
              </select>
            </div>
          </vs-col>
          <vs-col vs-lg="6" vs-sm="12">
            <div class="drop-down">
              <label for="models">Choose Model</label>
              <select id="models" v-model="model_id">
                <option value="0" selected disabled>Choose Model</option>
                <option v-for="(model, index) in modelsOfBrand" :value="model.id" :key="index">{{model.name_en}}
                </option>
              </select>
            </div>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-lg="6" vs-md="12">
            <vs-input v-model="name_en" class="input" placeholder="Category Name English"/>
          </vs-col>
          <vs-col vs-lg="6" vs-md="12">
            <vs-input v-model="name_ar" class="input" placeholder="Category Name Arabic"/>
          </vs-col>
          <vs-col vs-lg="6" vs-md="12">
            <vs-input v-model="price" class="input" placeholder="Price" type="number"/>
          </vs-col>
        </vs-row>
      </div>
    </div>
    <div class="body">
      <vs-row>
        <vs-col vs-lg="6" vs-sm="12">
          <div class="drop-down">
            <label for="slider">Choose Slider Gallery</label>
            <select id="slider" v-model="slider_id">
              <option value="0" selected disabled>Choose Gallery</option>
              <option v-for="(gallery, index) in galleries" :value="gallery.id" :key="index">{{gallery.name}}
              </option>
            </select>
          </div>
        </vs-col>
        <vs-col vs-lg="6" vs-sm="12">
          <div class="drop-down">
            <label for="category">Choose Category Gallery</label>
            <select id="category" v-model="gallery_id">
              <option value="0" selected disabled>Choose Gallery</option>
              <option v-for="(gallery, index) in galleries" :value="gallery.id" :key="index">{{gallery.name}}
              </option>
            </select>
          </div>
        </vs-col>
      </vs-row>
    </div>
    <div class="body options">
      <h2>Specs</h2>
      <vs-row>
        <vs-col vs-lg="6" vs-md="12" v-for="(spec, index) in boolSpecs" :key="index">
          <div class="checkboxes">
            <vs-checkbox v-model="chosen_specs_IDs" :vs-value="spec.id" class="checkbox">{{spec.name_en}}
            </vs-checkbox>
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-lg="6" vs-md="12" v-for="(spec, index) in textSpecs" :key="index">
          <div>
            <vs-input v-model="filled_specs_inputs[index].value" class="input" :label-placeholder="spec.name_en"/>
          </div>
        </vs-col>
      </vs-row>
    </div>
    <div class="body">
      <div>
        <h2>Accessories</h2>
      </div>
    </div>
    <div class="body">
      <h2>Settings</h2>
      <div class="switches">
        <div class="switch">
          <label for="switch">Show On Mobile</label>
          <vs-switch v-model="mobile_active" id="switch"/>
        </div>
        <div class="switch">
          <label for="switch">Test Drive</label>
          <vs-switch v-model="test_drive" id="switch"/>
        </div>
      </div>
    </div>
    <div class="optionBtns">
      <vs-button class="mr-5 save" icon-pack="feather" icon="icon-save" :disabled="invalidForm"
                 @click="confirmSaveData">
        Save
      </vs-button>
      <vs-button class="ml-5 cancel" type="border" color="primary" @click='resetData'>
        Reset
      </vs-button>
    </div>
  </div>
</template>

<script>
  import Breadcrumb from "../../../../components/general/breadcrumb";
  import ImgUpload from "../../../../components/general/img-upload";
  import UploadFile from "../../../../components/uploader/upload-file";

  export default {
    name: "add-service",
    components: {ImgUpload, Breadcrumb, UploadFile},
    data() {
      return {
        baseImagesURL: 'http://mansour-dev.s3.us-east-2.amazonaws.com/',
        brands: [],
        models: [],
        modelsOfBrand: [],
        colors: [],
        specs: [],
        categories: [],
        boolSpecs: [],
        textSpecs: [],
        name_en: '',
        name_ar: '',
        model_id: '0',
        brand_id: '0',
        price: '',
        description_en: '',
        description_ar: '',
        chosen_colors_IDs: [],
        chosen_specs_IDs: [],
        filled_specs_inputs: [],
        galleries: [],
        color_images: [],
        test_drive: true,
        mobile_active: true,
        web_active: true,
        gallery_id: '0',
        slider_id: '0'
      }
    },
    computed: {
      invalidForm() {

        let textSpecsArray;

        for (let i in this.filled_specs_inputs) {
          if (this.filled_specs_inputs[i].value) {
            textSpecsArray = true;
            break;
          }
        }

        if (this.name_en && this.name_ar && this.brand_id !== '0' && this.model_id !== '0' && this.price && this.gallery_id !== '0'
          && this.slider_id !== '0' && this.chosen_specs_IDs.length > 0 && textSpecsArray) {
          return false
        }
        return true;
      }
    },
    watch: {
      brand_id(v) {
        this.modelsOfBrand = [];
        for (let i = 0; i < this.models.length; i++) {
          if (v === this.models[i].brand.id) {
            this.modelsOfBrand.push(this.models[i]);
            //console.log(this.models[i]);
          }
        }
      }
    },
    methods: {
      getGalleries() {
        this.$httpCars
          .get("gallery-folders")
          .then(r => {
            this.$vs.loading.close();
            let total = r.data.data.total;
            console.log("total", total);
            this.$httpCars
              .get(`gallery-folders?per_page=${total}`)
              .then(r => {
                this.$vs.loading.close();
                this.galleries = r.data.data.data;
                console.log('Galleries: ', this.galleries);
              })
              .catch(() => {
                this.$vs.loading.close();
              });
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      },
      viewList() {
        this.$emit('cancel')
      },
      saveImage(imagesArray) {
        this.images = imagesArray;
        //console.log(this.images);
      },
      confirmSaveData() {
        this.$vs.dialog({
          title: 'Confirm',
          text: 'You are going to save these data!',
          color: 'primary',
          accept: () => this.saveData(),
          type: 'confirm'
        });
      },
      resetData() {
        this.image = null;
        this.$emit('cancel');
      },
      getImage(file) {
        this.images.push(file);
        //console.log(this.images);
      },
      saveData() {
        this.$vs.loading();

        let specs = [];

        for (let i in this.boolSpecs) {
          for (let x in this.chosen_specs_IDs) {
            let obj;
            if (this.boolSpecs[i].id === this.chosen_specs_IDs[x]) {
              obj = {
                id: this.boolSpecs[i].id,
                value: 1
              };
            } else {
              obj = {
                id: this.boolSpecs[i].id,
                value: 0
              };
            }
            specs.push(obj);
          }
        }

        for (let i in this.filled_specs_inputs) {
          if (this.filled_specs_inputs[i].value !== '') {
            specs.push(this.filled_specs_inputs[i]);
          }
        }

        let formData = new FormData();

        formData.append('name_en', this.name_en);
        formData.append('name_ar', this.name_ar);
        formData.append('model_id', this.model_id);
        formData.append('price', this.price);

        for (let i in specs) {
          formData.append(`specs[${i}][id]`, specs[i].id);
          formData.append(`specs[${i}][value]`, specs[i].value);
        }

        //console.log({chosen_specs: specs});

        formData.append('test_drive', this.test_drive ? '1' : '0');
        formData.append('mobile_active', this.mobile_active ? '1' : '0');

        formData.append('slider_id', this.slider_id);
        formData.append('category_gallery_id', this.gallery_id);


        //console.log({formData: formData});

        this.$httpCars.post(`/categories`, formData)
          .then(() => {
            this.$emit('saved');
            this.$vs.loading.close();
            //console.log(r);
            this.$vs.notify({
              title: 'Saved',
              text: 'Data is Saved Successfully',
              color: 'primary'
            });
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e);
            this.$vs.notify({
              title: 'Error',
              text: 'Error is occurred!\nCouldn\'t Save Data!',
              color: 'danger'
            });
          });
      },
      getSpecs() {
        this.$vs.loading();
        this.$httpCars.get('specs')
          .then(r => {
            this.$vs.loading.close();
            this.specs = r.data.data.data;
            //console.log({specs: this.specs});
            for (let i in this.specs) {
              if (this.specs[i].type === 'bool') {
                this.boolSpecs.push(this.specs[i]);
              } else if (this.specs[i].type === 'text') {
                this.textSpecs.push(this.specs[i]);
                this.filled_specs_inputs.push({
                  id: this.specs[i].id,
                  value: ''
                })
              }
            }
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e)
          })
      },
      getCategories() {
        this.$vs.loading();
        this.$httpCars
          .get(`/specs-categories`)
          .then(res => {
            this.$vs.loading();
            this.$httpCars
              .get(`/specs-categories?per_page=${res.data.data.total}`)
              .then(res => {
                this.$vs.loading.close();
                this.categories = res.data.data.data;
                //console.log(this.categories)
              })
              .catch(() => {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Something Error 🙁",
                  text: "Couldn't fetch data from the server",
                  color: 'danger'
                });
              })
          })
          .catch(() => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Something Error 🙁",
              text: "Couldn't fetch data from the server",
              color: 'danger'
            });
          })
      },
      getModels() {
        this.$vs.loading();
        this.$httpCars.get('models')
          .then(r => {
            this.$vs.loading.close();
            this.models = r.data.data.models;
            //console.log(this.models);
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e)
          })
      },
      getBrands() {
        this.$vs.loading();
        this.$httpCars.get('brands')
          .then(r => {
            this.$vs.loading.close();
            this.brands = r.data.data.brands;
            //console.log(this.models);
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e)
          })
      }
    },
    mounted() {
      this.getBrands();
      this.getModels();
      this.getSpecs();
      this.getGalleries();
      this.getCategories();
    }
  }
</script>

<style scoped lang="scss">

  .clr {
    clear: both;
  }

  .body {
    background-color: #fff;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;

    .drop-down {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;

      label {
        font-size: 1rem;
        padding: 5px;
      }

      select {
        padding: 10px;
        border-radius: 5px;
        width: 90%;
        background-color: #fff;
        border: 1px solid #00000030;
      }
    }


    .inputs {
      margin: 20px 0 20px 0;
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
    }
  }

  .input {
    width: 90%;
    margin-top: 30px;
  }

  .switches {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
    margin: 20px;
  }

  .checkboxes {
    margin: 10px;

    .checkbox {
      margin: 5px;
    }
  }

  .optionInput {
    margin-top: 30px;
    width: 50%;
    display: block;
    padding: 10px;
    border-radius: 5px;
    color: #00000090;
    margin-left: 10px;
  }

  h2 {
    margin: 30px;
  }

  .optionBtns {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  h4 {
    padding: 20px;
  }

  .subtitle {
    margin-top: 50px;
    color: #ccc;
  }
</style>
